import { configureStore } from "@reduxjs/toolkit";
import adminSlice from "../feature/admin/reducer";
import calendarSlice from "../feature/events/redux/slice";

const store = configureStore({
  reducer: {
    admin: adminSlice.reducer,
    calendar: calendarSlice.reducer,
  },
});

export default store;
