import React, { Suspense, lazy } from "react";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
import { Footer } from "./feature/footer";

import { NavBar } from "./feature/components/Navbar";
import { notification } from "antd";
import { Provider } from "react-redux";
import store from "./redux/store";
import { Loader } from "./components/Loader";
const HomeScreen = lazy(() => import("./feature/home/index"));
const AboutUsScreen = lazy(() => import("./feature/about/index"));
const ContactUs = lazy(() => import("./feature/contact/index"));
const EventsScreen = lazy(() => import("./feature/events/index"));
const AdminLogin = lazy(() => import("./feature/admin/Login"));
const BookingRequests = lazy(() => import("./feature/admin/BookingRequests"));
const CustomEvent = lazy(() => import("./feature/custom-event/index"));

const UserRouter = () => {
  return (
    <>
      <NavBar />
      <Outlet />
      <Footer />
    </>
  );
};

const AdminRouter = () => {
  return (
    <div
      style={{
        height: "100vh",
        overflowY: "scroll",
        backgroundColor: "#d7f8ce",
      }}
    >
      <Outlet />
    </div>
  );
};

const RouteLoadingWrapper = ({ Component }) => {
  return (
    <Suspense fallback={<Loader />}>
      <Component />
    </Suspense>
  );
};

function App() {
  const contextHolder = notification.useNotification()[1];

  return (
    <div>
      <Provider store={store}>
        <BrowserRouter>
          <Routes>
            <Route Component={UserRouter}>
              <Route
                path=""
                element={<RouteLoadingWrapper Component={HomeScreen} />}
              />
              <Route
                path="events"
                element={<RouteLoadingWrapper Component={EventsScreen} />}
              />
              <Route
                path="about"
                element={<RouteLoadingWrapper Component={AboutUsScreen} />}
              />
              <Route
                path="contact"
                element={<RouteLoadingWrapper Component={ContactUs} />}
              />
              <Route
                path="pran-pratistha"
                element={<RouteLoadingWrapper Component={CustomEvent} />}
              />
            </Route>
            <Route path="admin" Component={AdminRouter}>
              <Route
                path="login"
                element={<RouteLoadingWrapper Component={AdminLogin} />}
              />
              <Route
                path="requests"
                element={<RouteLoadingWrapper Component={BookingRequests} />}
              />
            </Route>
          </Routes>
        </BrowserRouter>
      </Provider>
      {contextHolder}
    </div>
  );
}

export default App;
