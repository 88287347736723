import React from "react";
import { Link, useLocation } from "react-router-dom";
import "../../styles/navbar.scss";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../admin/reducer";

export const NavBar = () => {
  const location = useLocation();
  const isUserLoggedIn = useSelector((state) => state.admin.isUserLoggedIn);
  const dispatch = useDispatch();

  const logout = () => {
    localStorage.removeItem("token");
    dispatch(logoutUser());
  };

  return (
    <div>
      <nav className="root-navbar">
        <img className="logo" src="images/new_logo.svg" />
        <img className="logo" src="images/logo.svg" />
        <div className="contact-container">
          {isUserLoggedIn && (
            <button onClick={logout} className="logout-btn">
              Logout
            </button>
          )}
          <div className="contact">
            <span className="material-icons">call</span>
            <span>{`615 364 6262`}</span>
          </div>
        </div>
      </nav>
      <nav className="navbar">
        <Link
          className={`link ${location.pathname === "/" ? "active" : ""}`}
          to=""
        >
          Home
        </Link>
        <Link
          className={`link ${location.pathname === "/about" ? "active" : ""}`}
          to="about"
        >
          About
        </Link>
        <Link
          className={`link ${location.pathname === "/events" ? "active" : ""}`}
          to="events"
        >
          Events
        </Link>
        <Link
          className={`link ${location.pathname === "/contact" ? "active" : ""}`}
          to="contact"
        >
          Contact Us
        </Link>
        <Link
          className={`link ${
            location.pathname === "/pran-pratistha" ? "active" : ""
          }`}
          to="pran-pratistha"
        >
          Pran Pratistha / Parking
        </Link>
      </nav>
    </div>
  );
};
