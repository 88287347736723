const baseUrl = "https://np8tmm9rc6.us-east-2.awsapprunner.com";
// "https://temple-service-node.onrender.com";

export const Endpoints = {
  getAllEvents: `${baseUrl}/get/all/event`,
  bookEvent: `${baseUrl}/add/event`,
  adminLogin: `${baseUrl}/login`,
  acceptRequest: `${baseUrl}/update/status/event`,
  getAllAdminEvents: `${baseUrl}/admin/all/event`,
  contact: `${baseUrl}/add`,
  contactsList: `${baseUrl}/get/all`,
  deleteEvent: `${baseUrl}/delete/event`,
};
