import { notification } from "antd";
import axios from "axios";

export const request = async (httpConfig, showDefaultError = true) => {
  var response;
  try {
    const token = localStorage.getItem("token");
    response = await axios({
      url: httpConfig.url,
      data: httpConfig.data,
      method: httpConfig.method,
      ...(httpConfig.params && { params: httpConfig.params }),
      ...(Boolean(token) && { headers: { Authorization: token } }),
    });
    return { success: true, data: response.data };
  } catch (error) {
    showDefaultError &&
      notification.error({
        message: "Network Error",
        description: error.message,
      });
    const statusCode = error?.response?.status;
    if (statusCode === 401 || statusCode === 403) {
      localStorage.removeItem("token");
      window.location.href = window.location.origin + "/admin/login";
    }
    return { success: false, data: response };
  }
};
