import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  bookings: [],
};

const calendarSlice = createSlice({
  name: "calendar",
  initialState,
  reducers: {},
});

export default calendarSlice;
