import { createSlice } from "@reduxjs/toolkit";
import { Endpoints } from "../../network/endpoints";
import { request } from "../../network/request";

export const bookingTypes = {
  block: "block",
  unblock: "unblock",
  decline: "decline",
};

const initialState = {
  bookings: {
    [bookingTypes.block]: {
      apiStatus: "init",
      data: null,
    },
    [bookingTypes.unblock]: {
      apiStatus: "init",
      data: null,
    },
    [bookingTypes.decline]: {
      apiStatus: "init",
      data: null,
    },
  },
  deleteEvent: { apiStatus: "init", items: {} },
  loginApiStatus: "init",
  requests: {},
  isUserLoggedIn: Boolean(localStorage.getItem("token")),
};

// requests all we can store in key : value bookingId: "apiStatus"

const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    updateBookingsApiStatus(state, action) {
      state.bookings[action.payload.id].apiStatus = action.payload.apiStatus;
    },
    fetchedBookings(state, action) {
      state.bookings[action.payload.id].data = action.payload.data;
    },
    updateLoginApiStatus(state, action) {
      if (action.payload === "success") {
        state.isUserLoggedIn = true;
      }
      state.loginApiStatus = action.payload;
    },
    acceptRequest(state, action) {
      const { bookingId, apiStatus } = action.payload;
      state.requests[bookingId] = apiStatus;
    },
    logoutUser(state, action) {
      state.isUserLoggedIn = false;
    },
    updateDeleteEventApiStatus(state, action) {
      state.deleteEvent.apiStatus = action.payload;
    },
    deleteAcceptedEvent(state, action) {
      state.deleteEvent.items[action.payload] = true;
    },
  },
});

export const {
  updateBookingsApiStatus,
  updateLoginApiStatus,
  acceptRequest,
  logoutUser,
  updateDeleteEventApiStatus,
  deleteAcceptedEvent,
} = adminSlice.actions;

export function loginAdmin(userInfo: any) {
  return async function (dispatch: any) {
    dispatch(updateLoginApiStatus("pending"));
    const httpConfig = {
      url: Endpoints.adminLogin,
      method: "POST",
      data: userInfo,
    };

    const { success, data } = await request(httpConfig);
    if (success) {
      localStorage.setItem("token", data.token);
      dispatch(updateLoginApiStatus("success"));
    } else dispatch(updateLoginApiStatus("error"));
  };
}

export function asyncFetchBookings(id: string) {
  return async function (dispatch) {
    dispatch(updateBookingsApiStatus({ id, apiStatus: "pending" }));
    const today = new Date();
    const year = today.getFullYear();
    const oneYearAfter = new Date().setFullYear(year + 1);

    const httpConfig = {
      url: Endpoints.getAllEvents,
      method: "POST",
      data: {
        startDate: today.getTime(),
        endDate: oneYearAfter,
      },
      params: {
        type: id,
      },
    };
    const { success, data } = await request(httpConfig);

    if (success) {
      dispatch(adminSlice.actions.fetchedBookings({ id, data }));
      dispatch(updateBookingsApiStatus({ id, apiStatus: "success" }));
    } else dispatch(updateBookingsApiStatus({ id, apiStatus: "error" }));
  };
}

export function acceptBookingRequest({
  bookingId,
  eventDate,
}: {
  bookingId: number;
  eventDate: number;
}) {
  return async function (dispatch) {
    dispatch(acceptRequest({ bookingId, apiStatus: "pending" }));
    const httpConfig = {
      url: Endpoints.acceptRequest,
      method: "PUT",
      data: {
        eventId: bookingId,
        eventDate,
        status: "block",
      },
    };
    const { success, data } = await request(httpConfig);
    if (success) {
      dispatch(acceptRequest({ bookingId, apiStatus: "success" }));
    } else {
      dispatch(acceptRequest({ bookingId, apiStatus: "error" }));
    }
  };
}

export default adminSlice;
