import "./footer.scss";
import React from "react";

export const Footer = () => {
  return (
    <div className="footer-container">
      <div className="bottom-box">
        <img src="./images/logo.png" alt="logo" width="200px" />
        <div>
          <h3>Arti Times</h3>
          <p>Morning : 9:00 AM</p>
          <p>Evening : 7:00 PM (Summer)</p>
          <p>Evening : 6:00 PM (Winter)</p>
          <p>Day Light Saving Time</p>
        </div>
        <div>
          <h3>Get in touch with us</h3>
          <a>
            <span className="material-icons">call</span>
            <span>President Jack Patel :</span>
            <span>615-364-6262</span>
          </a>
          <a href="#">
            <span className="material-icons">mail</span>
            <span>umiyadhamtn@gmail.com</span>
          </a>
          <a href="https://maps.app.goo.gl/kbFTCuaeQH323H1c9" target="_blank">
            <span className="material-icons">location_on</span>
            <span>2241 Hobson Pike, Antioch, TN 37013</span>
          </a>
        </div>
      </div>
      <div className="ad">
        <p>&copy; 2024 SHREE UMIYA DHAM. All rights reserved.</p>
        <p>
          Website developed by
          <a href="https://www.nxtgenrocket.com/" target="_blank">
            Nextgen Rocket
          </a>
        </p>
      </div>
    </div>
  );
};
